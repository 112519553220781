<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveImage" :disabled="saveLoading">
          <b-spinner small v-if="saveLoading"></b-spinner>
          {{ $t('Save') }}
        </b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <div class="d-flex justify-content-between align-items-center">
          <b-form-group :label="$t('Image alternative text')" class="col-md-4 p-0">
            <b-form-input v-model="lazyImageObject.alt_text"></b-form-input>
          </b-form-group>

          <b-form-checkbox v-model="lazyImageObject.is_main" switch>
            {{ $t('Set this image as main product image') }}
          </b-form-checkbox>

      </div>
      <image-edit-form v-model="lazyImageObject.file" :key="lazyImageObject.id"></image-edit-form>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BButton, BFormGroup, BFormInput, BFormCheckbox} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import ImageEditForm from "@/views/cloudcart/images/ImageEditForm";

export default {
  name: "ImageEditPanel",
  components: {
    ImageEditForm,
    BSidebar, BButton, BFormGroup, BFormInput, BFormCheckbox,
    CSidebarHeader
  },
  props: {
    image: {
      type: Object,
      validator: prop => prop.file instanceof File
    }
  },
  created() {
    this.lazyImageObject = window._.clone(this.image);
  },
  data() {
    return {
      saveLoading: false,
      lazyImageObject: {
        file: new File([1, 2, 3], 'empty.jpg')
      }
    }
  },
  methods: {
    saveImage() {
      this.$emit('input', this.lazyImageObject)
      this.$emit('hidden')
    }
  },
  watch: {
    image(newValue) {
      this.lazyImageObject = window._.clone(newValue)
    }
  }
}
</script>