<template>
  <div>
    <div v-b-visible="handleBreakpoint" class="d-block d-md-none position-fixed"></div>
    <c-navbar v-if="!hideNavbar">
      <template v-slot:title>
        {{ $t('Products') }}
        <feather-icon icon="ChevronsRightIcon"></feather-icon>
        {{ $t('Add product') }}
      </template>
      <template v-slot:buttons>
        <b-form-checkbox
            switch inline>
          {{ $t('Hidden') }}
        </b-form-checkbox>
        <b-form-checkbox
            switch inline>
          {{ $t('New') }}
        </b-form-checkbox>
        <b-form-checkbox
            switch inline>
          {{ $t('Featured') }}
        </b-form-checkbox>
        <b-button variant="secondary" class="mr-2">
          {{ $t('Save as draft') }}
        </b-button>
        <b-button variant="primary">
          {{ $t('Publish online') }}
        </b-button>
      </template>
    </c-navbar>

    <b-form>
      <b-card>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('Title')">
              <b-form-input v-model="product.name"
                            :placeholder="$t('For example: blouse with short sleeves')"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <c-input-url-prefix :label="$t('URL address')"
                                v-model="product.slug"
                                prefix="/product/"
                                :placeholder="$t('Enter URL address (optional)')"
                                :info="$t('This is the URL address to access the record which you are editing at the moment. This address will be accessible to all visitors of the site.')"></c-input-url-prefix>
          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col md="8">
          <b-card>
            <b-tabs>
              <b-tab :title="tab.title" v-for="(tab, index) in productTabs" :key="index"
                     @dblclick="tab.editable = true">
                <template #title>
                  <span @dblclick="tab.editable = true" v-if="!tab.editable">{{ tab.title }}</span>
                  <b-form-input v-model="tab.title" v-if="tab.editable" size="sm" @blur="tab.editable=false"
                                autofocus></b-form-input>
                  <b-button-close class="ml-1" style="position: absolute; top: 0; right:0" v-if="index > 0"
                                  @click="productTabs.splice(index, 1)"></b-button-close>
                </template>
                <c-rich-text-area v-model="tab.content"></c-rich-text-area>
              </b-tab>
              <template #tabs-end>
                <b-nav-item
                    role="presentation"
                    @click.prevent="addNewProductTab"
                >
                  <b-button variant="flat-primary ml-2">{{ $t('Add new tab') }}</b-button>
                </b-nav-item>
              </template>
            </b-tabs>
          </b-card>
          <div class="d-flex justify-content-end" v-if="!shortDescriptionVisible">
            <b-button variant="flat-primary" @click="shortDescriptionVisible = true">
              <feather-icon icon="PlusCircleIcon"></feather-icon>
              {{ $t('Add short description') }}
            </b-button>
          </div>
          <b-card v-if="shortDescriptionVisible">
            <b-card-title class="d-flex justify-content-between align-items-center">
              {{ $t('Short description') }}
              <b-button variant="flat-primary" @click="shortDescriptionVisible = false">
                {{ $t('Remove short description') }}
              </b-button>
            </b-card-title>
            <c-rich-text-area v-model="product.short_description"></c-rich-text-area>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card no-body class="py-1 px-2">
            <categories-panel :visible="categoryPanel"
                              width="70%"
                              @hidden="categoryPanel = false"
            ></categories-panel>
            <b-card-title class="d-flex flex-row justify-content-between align-items-center">
              <div>{{ $t('Category') }}</div>
              <b-button variant="flat-primary" @click="categoryPanel = true">
                <feather-icon icon="PlusCircleIcon"></feather-icon>
                {{ $t('Add category') }}
              </b-button>
            </b-card-title>
            <b-card-body class="p-0">
              <b-form-group :label="$t('Choose category for the product or create new')">
                <c-vue-select :placeholder="$t('Choose category')"></c-vue-select>
              </b-form-group>
            </b-card-body>
          </b-card>
          <b-card no-body class="py-1 px-2">
            <b-card-title class="d-flex flex-row justify-content-between align-items-center">
              <div>{{ $t('Manufacturer') }}</div>
              <b-button variant="flat-primary" @click="manufacturerPanel = true">
                <feather-icon icon="PlusCircleIcon"></feather-icon>
                {{ $t('Add manufacturer') }}
              </b-button>
            </b-card-title>
            <b-card-body class="p-0">
              <b-form-group :label="$t('Choose manufacturer for the product or create new')">
                <c-vue-select :placeholder="$t('Choose manufacturer')"></c-vue-select>
              </b-form-group>
              <manufacturers-panel :manufacturer="{}" @input="v => product.manufacturer = v" width="70%"
                                   :visible="manufacturerPanel"
                                   @hidden="manufacturerPanel = false"></manufacturers-panel>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-title>{{ $t('Product configuration') }}</b-card-title>
            <b-card-body class="p-0 d-flex flex-column">
              <p class="small text-center">
                {{
                  $t('What kind of product do you want to sell? Choose Digital if your clients should download it to receive it (for example electronic book or another file). Choose Physical if it requires delivery (for example clothes, phones, food).')
                }}</p>
              <div class="d-flex flex-row justify-content-center align-items-center">
                <div class="ml-md-3 col-md-2">
                  <c-vue-select v-model="selectedProductType" :clearable="false" :filterable="false"
                                :reduce="v => v.value"
                                :options="[{label: $t('Physical'), value: 'simple'}, {label: $t('Digital'), value: 'digital'}]"></c-vue-select>
                </div>
                <div class="col-md-1 ml-0 pl-0">
                  <feather-icon icon="InfoIcon"
                                v-b-tooltip.hover.top="$t('The physical products are real articles which has weight and should be delivered to address. The digital products are files which can be downloaded, they doesn\'t have weight and doesn\'t require delivery.')"></feather-icon>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center mt-3" v-if="selectedProductType === 'digital'">
                <div class="col-md-4 small text-center ml-md-n5">
                  {{
                    $t('The clients will be able to download the file when they make their order. Allowable file extensions are - .pdf, .gz, .gzip, .zip, .tar, .tar.gz, .tgz, .7z')
                  }}
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center" v-if="selectedProductType === 'digital'">
                <div class="col-md-4 ml-md-n5">
                  <c-form-file v-model="digitalFile"
                               accept=".pdf, .gz, .gzip, .zip, .tar, .tar.gz, .tgz, .7z"></c-form-file>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card>
            <div class="d-flex flex-row justify-content-center flex-wrap">
              <div class="col" v-if="selectedProductType !== 'digital'">
                <b-form-group class="text-center">
                  <b-form-checkbox
                      v-model="requiresDelivery"
                      switch>
                  </b-form-checkbox>
                  <span class="small">{{ $t('Requires delivery') }}
                    <feather-icon icon="InfoIcon"
                                  v-b-tooltip.hover.top="$t('If you choose that option the product will require delivery and you should enter product weight.')"></feather-icon>
                  </span>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-center">
                  <b-form-checkbox
                      v-model="quantityTracking"
                      switch>
                  </b-form-checkbox>
                  <span class="small">{{ $t('Quantity tracking') }}
                    <feather-icon icon="InfoIcon"
                                  v-b-tooltip.hover.top="$t('Enabling this option the product quantity will be tracked by the system. When a product is sold the rest quantity will be recalculated based on total quantity of the product.')"></feather-icon>
                  </span>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-center">
                  <b-form-checkbox
                      v-model="continueSales"
                      switch>
                  </b-form-checkbox>
                  <span class="small">{{ $t('Continue sales') }}
                    <feather-icon icon="InfoIcon"
                                  v-b-tooltip.hover.top="$t('Enabling this option will allow product orders even if the product quantity is zero.')"></feather-icon>
                  </span>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-center">
                  <div class="d-flex flex-row justify-content-center">
                    <b-form-checkbox
                        v-model="minimumQuantityNotification"
                        switch>
                    </b-form-checkbox>
                    <b-form-input inline size="sm" style="max-width: 120px" type="number" min="1"
                                  v-model="minimumQuantityNotificationValue"
                                  :disabled="!minimumQuantityNotification"></b-form-input>
                  </div>
                  <span class="small">{{ $t('Minimum quantity notification') }}
                    <feather-icon icon="InfoIcon"
                                  v-b-tooltip.hover.top="$t('Enabling this option the system will notify you for reaching minimum quantity of the product. Enter amount for which you want to be notified.')"></feather-icon>
                  </span>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group class="text-center">
                  <div class="d-flex flex-row justify-content-center">
                    <b-form-input inline size="sm" style="max-width: 120px" type="number" min="1"
                                  v-model="minimumOrderQuantity"></b-form-input>
                  </div>
                  <span class="small">{{ $t('Minimum quantity') }}
                    <feather-icon icon="InfoIcon"
                                  v-b-tooltip.hover.top="$t('Minimal quantity for order')"></feather-icon>
                  </span>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex flex-md-row flex-column">
              <div class="col-md-6">
                <b-form-group>
                  <template #label>
                    {{ $t('Status of the product when available') }}
                  </template>
                  <c-vue-select :options="availabilityOptions" :reduce="v => v.value"
                                :placeholder="$t('Choose')"></c-vue-select>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group>
                  <template #label>
                    {{ $t('Status of the product when not available') }}
                  </template>
                  <c-vue-select :options="availabilityOptions" :reduce="v => v.value" :placeholder="$t('Choose')"
                                :disabled="!quantityTracking"></c-vue-select>
                </b-form-group>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card>
            <b-table-lite :stacked="stacked" :fields="variantFields" :items="variantItems">
              <template #cell(price)="data">
                <b-form-input v-model="data.item.price" size="sm"></b-form-input>
              </template>
              <template #cell(sku)="data">
                <b-form-input v-model="data.item.sku" size="sm"></b-form-input>
              </template>
              <template #cell(barcode)="data">
                <b-form-input v-model="data.item.barcode" size="sm"></b-form-input>
              </template>
              <template #cell(quantity)="data">
                <b-form-input v-model="data.item.quantity" size="sm" :disabled="!quantityTracking"></b-form-input>
              </template>
              <template #cell(weight)="data">
                <b-form-input v-model="data.item.weight" size="sm" :disabled="!requiresDelivery"></b-form-input>
              </template>
            </b-table-lite>
            <div class="d-flex flex-column justify-items-center">
              <p class="text-center">
                {{
                  $t('You can add more options and variants for your product by creating different types of the product (example: color, size....)')
                }}</p>
              <p class="text-center"
                 v-html="$t('To add more variants please press the button <strong>{button_name}</strong>', {button_name: '&quot;'+$t('I have more variants')+'&quot;'})"></p>
              <div class="col text-center">
                <b-button variant="primary" class="text-uppercase" @click="variantsPanel = true">
                  {{ $t('I have more variants') }}
                  <feather-icon icon="InfoIcon"
                                v-b-tooltip.hover.top="$t('Click here to add more product variants.')"></feather-icon>
                </b-button>
                <product-variants-panel :product-id="product.id" width="70%"
                                        :visible="variantsPanel"
                                        @hidden="variantsPanel = false"
                ></product-variants-panel>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8">
          <b-row>
            <b-col>
              <b-card>
                <b-card-title>{{ $t('Product photos') }}
                  <feather-icon icon="InfoIcon"
                                v-b-tooltip.hover.top="$t('Allowed image extensions are - jpeg, jpg, jpe, gif, png, svg, webp. The maximum file size is 10 MB. The image resolution should be less than 15744 x 11808 pixels')"></feather-icon>
                </b-card-title>
                <c-media-collection></c-media-collection>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <b-card-title class="d-flex flex-row justify-content-between mb-0 align-items-center">
                  <span>{{ $t('Files') }}</span>
                  <b-button variant="flat-primary" @click="openProductFilesUploadDialog">
                    <feather-icon icon="PlusIcon"></feather-icon>
                    {{ $t('Upload files') }}
                  </b-button>

                  <b-form-file
                      multiple
                      style="display: none"
                      v-model="productFiles"
                      ref="productFilesInput"
                      accept=".pdf, .gz, .gzip, .zip, .tar, .tar.gz, .tgz, .7z"
                      :placeholder="$t('Choose a file or drop it here...')"
                      :drop-placeholder="$t('Drop file here...')"></b-form-file>


                </b-card-title>
                <span class="text-muted small">{{ $t('You can select file to add to your product') }}</span>
                <b-table-lite :items="productFilesComputed"
                              v-if="productFilesComputed.length"
                              class="text-center"
                              small
                              :fields="[{key: 'name', label: $t('Name')}, {key: 'title', label: $t('Title')}, {key: 'publish', label: $t('Publish file')}, {key: 'remove', label: $t('Remove file')}]">
                  <template #cell(name)="data">
                    <span class="small">{{ data.item.name }}</span>
                  </template>
                  <template #cell(title)="data">
                    <div class="d-flex justify-content-center align-items-center">
                      <c-form-input v-model="data.item.title" size="sm" :placeholder="data.item.name"
                                    class="mb-0"></c-form-input>
                    </div>
                  </template>
                  <template #cell(publish)="data">
                    <div class="d-flex justify-content-center">
                      <b-form-checkbox v-model="data.item.published"></b-form-checkbox>
                    </div>
                  </template>
                  <template #cell(remove)="data">
                    <div class="d-flex justify-content-center">
                      <b-button-close @click="removeProductFile(data.item.name)"></b-button-close>
                    </div>
                  </template>
                </b-table-lite>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <b-card-title>
                  {{ $t('Additional settings') }}
                </b-card-title>
                <b-row>
                  <b-col md="4">
                    <c-form-input :label="$t('Width (mm\\in)')" type="number"></c-form-input>
                  </b-col>
                  <b-col md="4">
                    <c-form-input :label="$t('Length (mm\\in)')" type="number"></c-form-input>
                  </b-col>
                  <b-col md="4">
                    <c-form-input :label="$t('Height (mm\\in)')" type="number"></c-form-input>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <b-card-title>
                  {{ $t('SEO configuration') }}
                </b-card-title>
                <b-row>
                  <b-col>
                    <c-seo-preview :description="product.seo_description" :link="productUrl"
                                   :title="product.seo_title"></c-seo-preview>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <c-form-input :label="$t('SEO page title')" v-model="product.seo_title"
                                  :placeholder="$t('Enter unique SEO page title')"></c-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <c-form-textarea :label="$t('SEO meta description')" v-model="product.seo_description"
                                     :placeholder="$t('Enter unique SEO meta description')"></c-form-textarea>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4">
          <b-card>
            <b-card-title>{{ $t('Order number') }}
              <p class="text-muted small mt-1">
                {{
                  $t('The smaller the number, the more forward the product will be. If there are two or more products with the same sort number, then they will be sorted by date of creation, with the last product created first. If it is empty, the product will be the last.')
                }}</p>
              <b-form-input v-model="product.sort" type="number" min="0"></b-form-input>
            </b-card-title>
          </b-card>
          <b-card>
            <b-card-title>{{ $t('Tags') }}</b-card-title>
            <p class="text-muted small mb-0">
              {{ $t('The tags will help your clients to find similar products.') }}</p>
            <p class="text-muted small">{{ $t('To save the tag press Enter or comma.') }}</p>
            <c-vue-select v-model="product.tags" multiple taggable push-tags></c-vue-select>
          </b-card>
        </b-col>
      </b-row>

    </b-form>

  </div>
</template>

<script>
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import CRichTextArea from "@/views/cloudcart/_elements/CRichTextArea";
import CFormFile from "@/views/cloudcart/_elements/CFormFile";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";
import CFormInput from "@/views/cloudcart/_elements/CFormInput"
import CFormTextarea from "@/views/cloudcart/_elements/CFormTextarea";
import ProductVariantsPanel from "@/views/cloudcart/products/Variants/ProductVariantsPanel";

import {
  BButton, BFormCheckbox, BCard, BRow, BCol,
  BFormGroup, BFormInput, BForm, VBTooltip,
  BTabs, BTab,
  BCardTitle, BCardBody, BTableLite, VBVisible,
  BButtonClose, BNavItem, BFormFile
} from "bootstrap-vue";
import CategoriesPanel from "@/views/cloudcart/categories/CategoriesPanel";
import CSeoPreview from "@/views/cloudcart/_elements/CSeoPreview";
import CMediaCollection from "@/views/cloudcart/_elements/CMediaCollection";
import ManufacturersPanel from "@/views/cloudcart/manufacturers/ManufacturersPanel";
import CInputUrlPrefix from "@/views/cloudcart/_elements/CInputUrlPrefix";

export default {
  props: {
    hideNavbar: {
      type: Boolean,
      default: false
    }
  },
  name: "ProductForm",
  components: {
    CInputUrlPrefix,
    ManufacturersPanel,
    CMediaCollection,
    CategoriesPanel,
    CFormInput,
    CNavbar,
    BButton, BFormCheckbox, BCard,
    BCol, BRow, BFormGroup, BFormInput, BForm,
    BTabs, BTab,
    BCardTitle, BCardBody, BTableLite,
    BButtonClose, BNavItem, BFormFile,
    CVueSelect,
    CRichTextArea,
    CFormFile,
    CFormTextarea,
    CSeoPreview,
    ProductVariantsPanel
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-visible': VBVisible
  },
  data() {
    return {
      product: {},
      shortDescriptionVisible: false,
      selectedProductType: 'simple',
      digitalFile: null,
      minimumQuantityNotification: false,
      minimumQuantityNotificationValue: 1,
      minimumOrderQuantity: 1,
      availabilityOptions: [
        {label: this.$t('Available'), value: "available"},
        {label: this.$t('Not available'), value: "not_available"},
      ],
      requiresDelivery: false,
      quantityTracking: false,
      continueSales: false,
      variantFields: [
        {key: 'price', label: this.$t('Price (BGN)')},
        {key: 'sku', label: this.$t('SKU')},
        {key: 'barcode', label: this.$t('Barcode')},
        {key: 'quantity', label: this.$t('Quantity')},
        {key: 'weight', label: this.$t('Weight (KG.)')},
      ],
      variantItems: [
        {price: null, sku: null, barcode: null, quantity: null, weight: null}
      ],
      stacked: false,
      productFiles: [],
      productTabs: [
        {key: 'description', title: this.$t('Description'), content: null, editable: false}
      ],
      categoryPanel: false,
      variantsPanel: false,
      manufacturerPanel: false
    }
  },
  computed: {
    productUrl() {
      return 'http://localhost/product/' + window._.get(this.product, 'slug', '')
    },
    productFilesComputed() {
      return window._.map(this.productFiles, file => {
        return {
          name: file.name,
          size: file.size,
          type: file.type,
          file: file
        }
      })
    }
  },
  methods: {
    handleBreakpoint(isVisible) {
      this.stacked = isVisible;
    },
    openProductFilesUploadDialog() {
      this.$refs.productFilesInput.$refs.input.click()
    },
    addNewProductTab() {
      this.productTabs.push({
        title: this.$t('New tab'),
        content: null,
        editable: false
      })
    },
    removeProductFile(fileName) {
      let fileIndex = window._.findIndex(this.productFiles, {name: fileName});
      this.productFiles.splice(fileIndex, 1)
    }
  },
  watch: {
    'product.name': function (newValue, oldValue) {
      if (!oldValue || window._.isEmpty(this.product.slug) || this.$slugify(oldValue) === this.product.slug) {
        this.product.slug = this.$slugify(newValue)
      }
    }
  }
}
</script>

