<template>
  <div>
    <p v-if="!title && !description">
      {{ $t('Enter SEO title and description to see how it look in Google search results.') }}</p>
    <b-row v-if="title || description">
      <b-col md="6" class="d-flex flex-column mb-2">
        <span class="seo-google-title">{{ title }}</span>
        <span class="seo-google-url">{{ link }}</span>
        <span class="seo-google-description">{{ description }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BRow, BCol} from "bootstrap-vue";
export default {
  props: {
    title: {
      validator: prop => typeof prop === 'string' || typeof prop === 'undefined',
      required: true
    },
    link: {
      validator: prop => typeof prop === 'string' || typeof prop === 'undefined',
      required: true
    },
    description: {
      validator: prop => typeof prop === 'string' || typeof prop === 'undefined',
      required: true
    }
  },
  name: "CSeoPreview",
  components: {BRow, BCol}
}
</script>

<style scoped>
.seo-google-title {
  font-family: Arial, sans-serif;
  font-size: 18px;
  line-height: 1.2;
  color: #1a0dab;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 3px;
}

.seo-google-url {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.15;
  color: #006621;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.seo-google-description {
  font-family: Arial, sans-serif;
  font-size: 13px;
  line-height: 1.4;
  color: #545454;
  margin-bottom: 0;
}
</style>