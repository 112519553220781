<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveVideo">
          {{ $t('Save') }}
        </b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <youtube-video-form v-model="lazyVideoObject.link" preview></youtube-video-form>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BButton} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import YoutubeVideoForm from "@/views/cloudcart/images/YoutubeVideoForm";

export default {
  name: "YoutubeVideoPanel",
  components: {
    YoutubeVideoForm,
    BSidebar, BButton,
    CSidebarHeader
  },
  props: {
    video: {
      required: true,
      validator: prop => prop.type === 'video'
    }
  },
  data() {
    return {
      lazyVideoObject: {},
    }
  },
  created() {
    this.lazyVideoObject = window._.clone(this.video)
  },
  methods: {
    saveVideo() {
      this.$emit('input', this.lazyVideoObject)
      this.$emit('hidden')
    }
  },
  watch: {
    'lazyVideoObject.link': function (newValue) {
      if (!newValue) {
        return;
      }
      let url = new URL(newValue)
      let videoId = url.searchParams.get("v")
      if (!videoId) {
        return;
      }

      this.lazyVideoObject.file = 'https://img.youtube.com/vi/' + videoId + '/0.jpg'
    }
  }
}
</script>