<template>
  <v-select v-bind="$attrs" v-on="$listeners" :select-on-key-codes="[188, 13]">
    <template v-for="field in Object.keys($scopedSlots)" v-slot:[field]="option">
      <slot :name="field" :option="option"/>
    </template>
    <template #no-options v-if="!_.has($scopedSlots, 'no-options')">
      {{$t('No options found')}}
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: "CVueSelect",
  components: {
    vSelect
  }
}
</script>