<template>
  <div>
    <div v-b-visible="handleBreakpoint" class="d-block d-md-none position-fixed"></div>
    <b-table v-bind="$attrs" v-on="$listeners" :ref="'table' + _uid" responsive="md" @row-selected="onRowSelected" :stacked="stacked"
             :empty-text="$t('No matching records found')"
    >
      <template v-for="field in Object.keys($scopedSlots)" v-slot:[field]="{item}">
        <slot :name="field" :item="item"/>
      </template>
      <template #cell(selection)="data" v-if="hasSelection">
        <b-form-checkbox v-model="data.rowSelected" @change="toggleRowSelected(data.index)"></b-form-checkbox>
      </template>
      <template #head(selection) v-if="hasSelection">
        <b-form-checkbox v-model="selectAll"></b-form-checkbox>
      </template>
    </b-table>

  </div>
</template>

<script>
import {BTable, BFormCheckbox, VBVisible} from "bootstrap-vue";

export default {
  name: "CTable",
  components: {BTable, BFormCheckbox},
  directives: {
    'b-visible': VBVisible
  },
  props: {
    hasSelection: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectAll: false,
      stacked: true
    }
  },
  methods: {
    selectAllRows() {
      this.$refs['table' + this._uid].selectAllRows()
    },
    deselectAllRows() {
      this.$refs['table' + this._uid].clearSelected()
    },
    onRowSelected(items) {
      this.$emit('rowSelected', items);
    },
    toggleRowSelected(index) {
      if (!this.$refs['table' + this._uid].isRowSelected(index)) {
        this.$refs['table' + this._uid].selectRow(index)
      } else {
        this.$refs['table' + this._uid].unselectRow(index)
      }
    },

    handleBreakpoint(isVisible){
      this.stacked = isVisible;
    }
  },
  watch: {
    selectAll(newValue) {
      if (newValue === true) {
        this.selectAllRows();
      } else {
        this.deselectAllRows();
      }
    }
  }
}
</script>