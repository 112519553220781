<template>
  <div v-if="value">
    <slot v-bind:priceFormatted="price">
            <span>{{ formatNumber(_.get(price, '0.value')) }}<sup
                v-if="fractionDigits!==0">{{ _.get(price, '2.value') }}</sup><span
                v-if="!disableCurrency">{{ _.get(price, '4.value') }}</span></span>
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      required: true,
      default: 0
    },
    fractionDigits: {
      type: Number,
      default: 2
    },
    locale: {
      type: String,
      default: 'bg-BG'
    },
    currency: {
      type: String,
      default: 'BGN'
    },
    disableCurrency: {
      required: false,
      default: false,
      type: Boolean
    },
    fromCents: {
      required: false,
      default: false,
      type: Boolean
    },
    thousandsSeparator: {
      required: false,
      default: ',',
      type: String
    }
  },
  name: "Price",
  computed: {
    price() {
      let value = typeof this.value === String ? this.fromCents ? parseInt(this.value) : parseFloat(this.value) : this.value;
      if (this.fromCents) {
        value = this.value / 100;
      }
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: this.fractionDigits,
        maximumFractionDigits: this.fractionDigits
      }).formatToParts(value);
    }
  },
  methods: {
    formatNumber(v) {
      return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);
    }
  }
}
</script>
