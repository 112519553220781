<template>
  <div :class="{'d-flex flex-row': !hidden, 'hidden': hidden}">
    <slot name="preview" v-if="preview">
      <div class="col-md-3">
        <div class="thumbnail-container border d-flex align-items-center justify-content-center" v-if="!fileSrc"><feather-icon icon="BoxIcon" size="30"></feather-icon></div>
        <b-img fluid :src="fileSrc" v-if="fileSrc"></b-img>
      </div>
    </slot>

    <div :class="{'col-md-9': preview}" class="d-flex flex-grow-1 align-items-center file-browse-container">
      <b-form-group :description="description">
        <template #label>
          <span class="text-muted small">{{label}}</span>
        </template>
        <b-form-file v-bind="$attrs"
                     @change="e => preview ? readFile(e) : null"
                     v-on="$listeners"
                     ref="fileInput"
                     :placeholder="$t('Choose a file or drop it here...')"
                     :drop-placeholder="$t('Drop file here...')"></b-form-file>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {BFormFile, BImg, BFormGroup} from "bootstrap-vue";

export default {
  props: {
    preview: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  name: "CFormFile",
  components: {BFormFile, BImg, BFormGroup},
  data(){
    return {
      fileSrc: null
    }
  },
  methods: {
    readFile(event){
      let files = event.target.files;
      if (!files.length) {
        return '';
      }
      let fileReader = new FileReader()
      fileReader.onload = e => {
        this.fileSrc = e.target.result
      }
      fileReader.readAsDataURL(files[0])
    }
  }
}
</script>
<style lang="scss" scoped>
.thumbnail-container {
  border-radius: 50% 50%;
  width: 100px;
  height: 100px;
}
.file-browse-container {
  fieldset {
    width: 100%
  }
}
</style>