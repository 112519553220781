<template>
  <quill-editor
      v-bind="$attrs"
      v-on="$listeners"
      :options="inputOptions"
  />
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {quillEditor} from 'vue-quill-editor'

export default {
  name: "CRichTextArea",
  props: {
    content: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: Object,
      required: false,
      default: function(){
        return {}
      }
    }
  },
  components: {
    quillEditor
  },
  computed: {
    inputOptions() {
      return Object.assign(this.options, {
        theme: 'snow',
        placeholder: '',
        height: 200
      })
    }
  }
}
</script>
<style>
.ql-editor {
  min-height: 125px;
}
</style>