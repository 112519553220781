<template>
  <div class="d-flex justify-content-center">
    <b-img :src="fileSrc" v-bind="$attrs" v-on="$listeners" v-if="fileSrc"></b-img>
    <div class="border placeholder text-muted" v-if="placeholder && !fileSrc">
      <feather-icon icon="ImageIcon" size="35"></feather-icon>
    </div>
    <div class="controls position-absolute" v-if="controls" :class="{'visible-controls': showControls}">
      <div class="control-placeholder top left">
        <slot name="top:left"></slot>
      </div>
      <div class="control-placeholder bottom left">
        <slot name="bottom:left"></slot>
      </div>
      <div class="control-placeholder top right">
        <slot name="top:right"></slot>
      </div>
      <div class="control-placeholder bottom right">
        <slot name="bottom:right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {BImg} from "bootstrap-vue";

export default {
  name: "CImg",
  components: {
    BImg
  },
  props: {
    image: {
      validator: prop => typeof prop === 'string' || typeof prop === 'object',
      required: true
    },
    placeholder: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    showControls: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileSrc: null
    }
  },
  created() {
    if (typeof this.image === 'string') {
      this.fileSrc = this.image;
    } else if (this.image) {
      this.readFile(this.image)
    }
  },
  methods: {
    readFile(file) {
      let fileReader = new FileReader()
      fileReader.onload = e => {
        this.fileSrc = e.target.result
      }
      fileReader.readAsDataURL(file)
    }
  },
  watch: {
    image(newValue) {
      if (typeof newValue === 'string') {
        this.fileSrc = newValue;
      } else {
        this.readFile(newValue)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.placeholder {
  width: 70px;
  height: 70px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.controls {
  width: 100%;
  height: 100%;
  top: 0;

  &:not(.visible-controls) {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }

  .control-placeholder {
    position: absolute;

    &.left {
      left: 3px;
    }

    &.right {
      right: 3px;
    }

    &.bottom {
      bottom: 3px;
    }

    &.top {
      top: 3px;
    }
  }
}
</style>