<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide" :title="$t('Variants')">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveVariants" :disabled="saveLoading">
          <b-spinner small v-if="saveLoading"></b-spinner>
          {{ $t('Save') }}
        </b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <b-overlay :show="showOverlay" rounded="sm">
        <product-variants-form :productId="productId"></product-variants-form>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BOverlay, BSpinner, BButton} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import ProductVariantsForm from "@/views/cloudcart/products/Variants/ProductVariantsForm";

export default {
  name: "ProductVariantsPanel",
  components: {
    BSidebar, BOverlay, BSpinner, BButton,
    CSidebarHeader,
    ProductVariantsForm
  },
  props: {
    productId: {
      validator: prop => typeof prop === 'number' || typeof prop === 'undefined',
      required: true
    }
  },
  data() {
    return {
      saveLoading: false,
      showOverlay: false
    }
  },
  methods: {
    saveVariants() {

    }
  }
}
</script>