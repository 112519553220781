<template>
  <b-overlay :show="showOverlay" rounded="sm">
    <b-row>
      <b-col md="7">
        <b-card>
          <cropper
              ref="cropper"
              class="cropper"
              :src="base64Image"
              :default-size="defaultSize"
              @change="cropChange"
              @ready="showOverlay = false"
          ></cropper>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card>
          <div>
            <b-img :src="canvas.toDataURL()" v-if="canvas !== null" fluid></b-img>
            <div class="d-flex flex-row mt-1">
              <span class="mr-2"><strong>{{ $t('Width') }}</strong>: {{ coordinates.width }}px</span>
              <span><strong>{{ $t('Height') }}</strong>: {{ coordinates.height }}px</span>
            </div>
          </div>
          <b-form-group :label="$t('Rotation')" class="mt-2">
            <vue-slider v-model="rotation" :min="-180" :max="180" :interval="1"></vue-slider>
          </b-form-group>
          <div class="d-flex justify-content-between">
            <b-button size="sm" variant="primary" @click="flip('horizontal')">{{ $t('Flip horizontal') }}
            </b-button>
            <b-button size="sm" variant="primary" @click="flip('vertical')">{{ $t('Flip vertical') }}</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {BOverlay, BRow, BCol, BImg, BFormGroup, BButton, BCard} from "bootstrap-vue";
import {Cropper} from 'vue-advanced-cropper'
import VueSlider from 'vue-slider-component'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-slider-component/theme/default.css'

export default {
  name: "ImageEditForm",
  props: {
    value: {
      required: true,
      validator: prop => prop instanceof File
    },
    alt: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    BOverlay, BRow, BCol,
    Cropper, BImg, BFormGroup,
    VueSlider, BButton, BCard
  },
  data() {
    return {
      showOverlay: true,
      lazyFile: new File([1, 2, 3], 'empty.jpg'),
      base64Image: '',
      canvas: null,
      coordinates: {},
      rotation: 0
    }
  },
  created() {
    this.lazyFile = this.value;
    this.convertToBase64()
  },
  methods: {
    cropChange({coordinates, canvas}) {
      this.$emit('coordinatesChange', coordinates)
      this.canvas = canvas
      this.coordinates = coordinates
      canvas.toBlob(blob => {
        this.lazyFile = new File([blob], this.lazyFile.name)
      })
    },
    convertToBase64() {
      let reader = new FileReader();
      reader.onload = e => {
        this.base64Image = e.target.result
      };
      reader.readAsDataURL(this.lazyFile);
    },
    rotate: window._.debounce(function (angle) {
      this.$refs.cropper.rotate(angle);
    }, 200),
    flip(direction) {
      let horizontal = direction === 'horizontal'
      let vertical = direction !== 'horizontal'
      this.$refs.cropper.flip(horizontal, vertical);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    }
  },
  watch: {
    lazyFile(newValue) {
      this.$emit('input', newValue)
    },
    rotation(v) {
      this.rotate(v)
    }
  }
}
</script>