<template>
  <div class="row">
    <div class="col-md-6">
      <c-form-file multiple v-model="lazyValue.files" accept=".jpg, .png, .webp, .jpeg"></c-form-file>
      <draggable v-model="lazyValue.files"
                 tag="ul"
                 class="list-group list-group-flush d-flex flex-md-column flex-wrap mt-2"
      >
        <b-list-group-item
            v-for="(file, index) in lazyValue.files"
            :key="index"
            tag="li"
            class="border align-items-center d-flex gx-1 list-item cursor-move justify-content-between"
        >
          <span>{{ file.name }}</span>
          <b-button class="btn-icon" variant="danger" size="sm" @click="lazyValue.files.splice(index, 1)">
            <feather-icon icon="TrashIcon"></feather-icon>
          </b-button>
        </b-list-group-item>
      </draggable>
    </div>
    <div class="col-md-6">
      <c360-viewer :image-files="lazyValue.files" :amount="lazyValue.files.length" :key="previewIndex"
                   v-if="lazyValue.files.length"></c360-viewer>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {BListGroupItem, BButton} from "bootstrap-vue";
import CFormFile from "@/views/cloudcart/_elements/CFormFile";
import C360Viewer from "@/views/cloudcart/_elements/C360Viewer";

export default {
  name: "Image360Form",
  components: {
    C360Viewer,
    BListGroupItem, BButton,
    CFormFile,
    draggable
  },
  props: {
    value: {
      type: Object,
      validator: prop => prop.type === '360' && prop.files instanceof Array
    }
  },
  data() {
    return {
      lazyValue: {
        files: []
      },
      previewIndex: 0
    }
  },
  created() {
    this.lazyValue = window._.clone(this.value);
  },
  watch: {
    lazyValue: {
      handler: window._.debounce(function (newValue) {
        this.previewIndex++
        this.$emit('input', newValue)
      }, 300, {leading: false, trailing: true}),
      deep: true
    }
  }
}
</script>