<template>
  <c-form-input :label="label" :info="info">
    <template v-slot:default>
      <b-input-group>
        <b-input-group-prepend is-text class="slug-prepend">
          {{ prefix }}
        </b-input-group-prepend>
        <b-form-input v-bind="$attrs" v-on="$listeners"
                      :placeholder="placeholder"></b-form-input>
      </b-input-group>
    </template>
  </c-form-input>
</template>

<script>
import {VBTooltip, BInputGroup, BInputGroupPrepend, BFormInput} from "bootstrap-vue";
import CFormInput from "@/views/cloudcart/_elements/CFormInput";

export default {
  props: {
    info: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    prefix: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  components: {
    CFormInput,
    BFormInput,
    BInputGroup, BInputGroupPrepend
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  name: "CInputUrlPrefix"
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables';

.slug-prepend {
  > .input-group-text {
    background-color: lighten($primary, 35%);
    color: $primary;
    border-color: $primary;
  }

  & + input {
    border-left-color: $primary
  }
}
</style>