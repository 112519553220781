<template>
  <b-navbar
      :toggleable="false"
      class="navbar header-navbar navbar navbar-shadow align-items-center navbar-light navbar-expand border mb-2"
  >
    <slot
        name="navbar"
        :toggleVerticalMenuActive="false"
        :navbarTypeClass="['fixed-top', 'header-navbar navbar navbar-shadow align-items-center']"
    >
      <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link
                class="nav-link"
                @click="toggleVerticalMenuActive"
            >
              <feather-icon
                  icon="MenuIcon"
                  size="21"
              />
            </b-link>
          </li>
        </ul>

        <!-- Left Col -->
        <div class="align-items-center flex-grow-1 d-none d-lg-flex">
          <h1 class="m-0 text-muted"><slot name="title"></slot></h1>
        </div>
        <slot name="buttons">

        </slot>
        <b-navbar-nav class="nav align-items-center ml-auto">
          <dark-toggler class="d-none d-lg-block" v-if="darkToggle === true"/>
          <notification-dropdown v-if="notifications === true"/>
        </b-navbar-nav>
      </div>
    </slot>
  </b-navbar>
</template>

<script>
import {
  BLink, BNavbar, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown";

export default {
  name: "CNavbar",
  components: {
    BLink,
    BNavbar,
    // Navbar Components
    BNavbarNav,
    DarkToggler,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
    darkToggle: {
      type: Boolean,
      required: false,
      default: true
    },
    notifications: {
      type: Boolean,
      required: false,
      default: true
    }
  },
}
</script>