<template>
  <div>
    <b-card>
      <draggable
          v-model="variantsList"
          tag="ul"
          class="list-group list-group-flush"
      >
        <b-list-group-item
            v-for="(item, index) in variantsList"
            :key="index"
            tag="li"
        >
          <div class="d-flex flex-row align-items-center">
            <div class="col flex-grow-0">
              <feather-icon icon="MenuIcon"></feather-icon>
            </div>
            <div class="col-md-3">
              <b-form-group :label="$t('Variants')" class="text-left">
                <c-vue-select :options="variantTypes" :reduce="v => v.value" v-model="item.type" push-tags
                              taggable></c-vue-select>
              </b-form-group>
            </div>
            <div class="col flex-grow-1">
              <b-form-group :label="$t('Variations')" class="text-left">
                <c-vue-select :options="_.get(variantTypesOptions, item.type, [])" v-model="item.variants"
                              :reduce="v => typeof v === 'object' ? v.value || v.label : v"
                              multiple
                              :key="'options' + item.type"
                              @option:created="v => addNewOptionTo(item.type, v)"
                              taggable
                              push-tags></c-vue-select>
              </b-form-group>
            </div>
            <div class="col flex-grow-0">
              <b-button variant="flat" class="btn-icon" @click="variantsList.splice(index, 1)">
                <feather-icon icon="XCircleIcon" size="22"></feather-icon>
              </b-button>
            </div>
          </div>
        </b-list-group-item>
      </draggable>
      <div class="d-flex justify-content-center">
        <b-button variant="flat-primary" @click="variantsList.push({})">
          <feather-icon icon="PlusCircleIcon"></feather-icon>
          {{ $t('Add new variant') }}
        </b-button>
      </div>
    </b-card>
    <b-card v-if="variantsVariations.length">
      <c-table :fields="tableFields" has-selection :items="variantsVariations" responsive small>
        <template #cell(picture)="data">
          <c-img :image="data.item.picture" placeholder responsive width="100px"></c-img>
        </template>
        <template #cell(actions)="data">
          <b-button variant="success" size="sm" :data-item="data.item" @click="editVariant(data.item)">
            {{ $t('Edit') }}
          </b-button>
        </template>
        <template #cell(price)="data">
          <price :value="data.item.price"></price>
        </template>
      </c-table>
    </b-card>
    <b-modal v-model="editVariantModal"
             :ok-title="$t('Save')"
             :cancel-title="$t('Cancel')"
             @ok="saveEditingVariant"
             @cancel="cancelEditingVariant"
    >
      <b-form v-if="editingVariant !== null">
        <div class="row">
          <div class="col-md-6">
            <c-form-input :label="$t('Price')" v-model="editingVariant.price" type="number" step="0.01"></c-form-input>
          </div>
          <div class="col-md-6">
            <c-form-input :label="$t('SKU')" v-model="editingVariant.sku"></c-form-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <c-form-input :label="$t('Barcode')" v-model="editingVariant.barcode"></c-form-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <c-form-input :label="$t('Quantity')" v-model="editingVariant.quantity" type="number"
                          step="0.001"></c-form-input>
          </div>
          <div class="col-md-6">
            <c-form-input :label="$t('Weight')" v-model="editingVariant.weight" type="number"
                          step="0.001"></c-form-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <c-form-file v-model="editingVariant.picture" preview></c-form-file>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BListGroupItem, BFormGroup, BModal,
  BForm
} from "bootstrap-vue";
import draggable from 'vuedraggable'
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";
import CTable from "@/views/cloudcart/_elements/CTable";
import CFormInput from "@/views/cloudcart/_elements/CFormInput";
import Price from "@/views/cloudcart/_elements/Price";
import CFormFile from "@/views/cloudcart/_elements/CFormFile";
import CImg from "@/views/cloudcart/_elements/CImg";

export default {
  name: "ProductVariantsForm",
  components: {
    BCard, BButton, BFormGroup,
    BListGroupItem, BModal, BForm,
    draggable,
    CVueSelect,
    CTable, CFormInput, Price, CFormFile, CImg
  },
  props: {
    productId: {
      validator: prop => typeof prop === 'number' || typeof prop === 'undefined',
      required: true
    }
  },
  data() {
    return {
      variantsList: [{}],
      variantTypes: [
        {value: 'size', label: this.$t('Size')},
        {value: 'color', label: this.$t('Color')},
        {value: 'material', label: this.$t('Material')},
      ],
      variantTypesOptions: {
        size: [
          {value: 'L', label: 'L'},
          {value: 'M', label: 'M'},
          {value: 'XL', label: 'XL'},
        ],
        color: [
          {value: 'black', label: this.$t('Black')},
          {value: 'red', label: this.$t('Red')},
          {value: 'blue', label: this.$t('Blue')},
        ]
      },
      editVariantModal: false,
      editingVariant: null,
      editingVariantOriginalState: null
    }
  },
  computed: {
    tableFields() {
      let baseFields = [{
        key: 'picture', label: this.$t('Picture')
      }];

      let dynamicFields = [];
      window._.forEach(this.variantsList, variant => {
        dynamicFields.push({
          key: variant.type, label: window._.get(window._.find(this.variantTypes, {value: variant.type}), 'label')
        })
      })
      let dataFields = [
        {key: 'price', label: this.$t('Price')},
        {key: 'sku', label: this.$t('SKU')},
        {key: 'barcode', label: this.$t('Barcode')},
        {key: 'quantity', label: this.$t('Quantity')},
        {key: 'weight', label: this.$t('Weight')},
        {key: 'actions', label: this.$t('Actions')}
      ]

      return [...baseFields, ...dynamicFields, ...dataFields]
    },
    variantsVariations() {
      let rows = [];
      let variantsList = window._.filter(this.variantsList, item => {
        return !window._.isEmpty(item) && !window._.isUndefined(item.type) && window._.get(item, 'variants', []).length
      })
      if (!variantsList.length) {
        return []
      }

      let options = [];
      variantsList.forEach(mainVariant => {
        options = [...options, mainVariant.variants]
      })
      this.getVariantVariations(options, (...items) => {
        let rowItem = {picture: null}
        for (let i = 0; i < variantsList.length; i++) {
          window._.set(rowItem, variantsList[i].type, items[i])
        }
        rows.push(rowItem)
      })

      return rows;
    }
  },
  methods: {
    addNewOptionTo(itemType, newOption) {
      let item = window._.get(this.variantTypesOptions, itemType);
      if (!item) {
        window._.set(this.variantTypesOptions, itemType, [])
      }
      item = window._.get(this.variantTypesOptions, itemType);
      if (typeof newOption === 'object') {
        item.push(newOption)
      } else {
        item.push({
          value: newOption,
          label: newOption
        })
      }
    },
    editVariant(variant) {
      this.editingVariantOriginalState = window._.clone(variant)
      this.editingVariant = window._.clone(variant)
    },
    saveEditingVariant() {
      // eslint-disable-next-line no-unused-vars
      let item = window._.find(this.variantsVariations, this.editingVariantOriginalState)
      item.price = this.editingVariant.price
      item.sku = this.editingVariant.sku
      item.barcode = this.editingVariant.barcode
      item.quantity = this.editingVariant.quantity
      item.weight = this.editingVariant.weight
      item.picture = this.editingVariant.picture
      this.editingVariant = null
      this.editingVariantOriginalState = null
    },
    cancelEditingVariant() {
      this.editingVariant = null
      this.editingVariantOriginalState = null
    },
    getVariantVariations(sets, f, context) {
      if (!context) context = this;
      let p = [], max = sets.length - 1, lens = [];
      for (let i = sets.length; i--;) lens[i] = sets[i].length;

      function dive(d) {
        let a = sets[d], len = lens[d];
        if (d === max) for (let i = 0; i < len; ++i) p[d] = a[i], f.apply(context, p);
        else for (let i = 0; i < len; ++i) p[d] = a[i], dive(d + 1);
        p.pop();
      }

      dive(0);
    }
  },
  watch: {
    editingVariant(newValue) {
      this.editVariantModal = newValue !== null
    }
  }
}
</script>