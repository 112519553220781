<template>
  <b-form-group
      :label="label"
      :description="description"
      :label-for="_uid.toString()"
      :valid-feedback="validFeedback"
      :invalid-feedback="invalidFeedback"
      :state="state"
  >
    <template #label>
      <div class="col-form-label pt-0">
        {{ label }} <feather-icon icon="InfoIcon"
                                  v-if="info"
                                  v-b-tooltip.hover.top="info"></feather-icon>
      </div>
    </template>
    <template v-for="field in Object.keys($scopedSlots)" v-slot:[field]>
      <slot :name="field"/>
    </template>
    <slot v-bind:id="_uid.toString">
      <b-form-input
          :id="_uid.toString()"
          v-bind="$attrs"
          v-on="$listeners"
          :state="state"
          :class="inputBg"
      ></b-form-input>
    </slot>
  </b-form-group>
</template>

<script>
import {BFormGroup, BFormInput, VBTooltip} from "bootstrap-vue";

export default {
  props: {
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    validFeedback: {
      type: String,
      required: false
    },
    invalidFeedback: {
      type: String,
      required: false
    },
    state: {
      type: Boolean,
      required: false,
      default: null
    },
    inputBg: {
      type: String,
      required: false,
      default: 'bg-white'
    },
    info: {
      type: String,
      required: false
    }
  },
  components: {BFormInput, BFormGroup},
  directives: {
    'b-tooltip': VBTooltip
  },
  name: "CFormInput"
}
</script>
<style scoped>
.col-form-label {
  font-size: 14px;
  padding-bottom: calc(0.038rem + 1px);
}
</style>