<template>
  <div class="d-flex flex-row">
    <div class="col-md-6 d-flex justify-content-center align-items-center" v-if="preview">
      <b-card class="w-100">
        <iframe id="ytplayer" type="text/html" width="100%" v-if="youtubeEmbedLink"
                class="embedded-video-16-9"
                :src="youtubeEmbedLink"
                frameborder="0"></iframe>
        <div class="border w-100 text-center" style="padding: 50px 0" v-if="!youtubeEmbedLink">
          <fa :icon="['fab', 'youtube']" style="font-size: 160px"></fa>
        </div>
      </b-card>
    </div>
    <div :class="{'col-md-6': preview}">
      <b-card>
        <c-form-input @change="v => $emit('input', v)" :value="value" :label="$t('Youtube video link')"
                      placeholder="https://www.youtube.com/watch?v=DImAUaDpf_w"></c-form-input>
      </b-card>
    </div>
  </div>
</template>

<script>
import CFormInput from "@/views/cloudcart/_elements/CFormInput";
import {BCard} from "bootstrap-vue";

export default {
  props: {
    value: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BCard,
    CFormInput
  },
  name: "YoutubeVideoForm",
  computed: {
    youtubeId() {
      if (!this.value) {
        return null;
      }

      let url = new URL(this.value)
      return url.searchParams.get("v")
    },
    youtubeEmbedLink() {
      if (!this.youtubeId) {
        return null
      }

      return 'https://www.youtube.com/embed/'+ this.youtubeId
    }
  }
}
</script>
<style scoped>
.embedded-video-16-9 {
  width:  90vw;
  height: 50.625vw; /* 90*9/16 */
}

@media (min-width: 893px) {
  .embedded-video-16-9 {
    width: 29vw;
    height: 16.3vw; /* 45*9/16 */
  }
}
</style>