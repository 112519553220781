<template>
  <div class="d-flex flex-row justify-content-between w-100 align-items-center">
    <div class="col flex-grow-0">
      <b-button-close @click="$emit('close')" class="align-self-center">
        <b-icon-x></b-icon-x>
      </b-button-close>
    </div>
    <div class="col flex-grow-0 text-nowrap">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div class="col flex-grow-1 text-right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {BButtonClose, BIconX} from "bootstrap-vue";

export default {
  name: "CSidebarHeader",
  props: {
    title: {
      type: String,
      required: false
    }
  },
  components: {
    BButtonClose, BIconX
  }
}
</script>