<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide" :title="$t('Add manufacturer')">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveManufacturer">
          {{ $t('Save') }}
        </b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <manufacturers-form manufacturer="manufacturer" @input="v => lazyManufacturer = v"></manufacturers-form>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BButton} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import ManufacturersForm from "@/views/cloudcart/manufacturers/ManufacturersForm";

export default {
  name: "ManufacturersPanel",
  components: {
    ManufacturersForm,
    BSidebar, BButton,
    CSidebarHeader
  },
  props: {
    manufacturer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lazyManufacturer: null
    }
  },
  methods: {
    saveManufacturer() {
      this.$emit('input',)
    }
  },
  watch: {
    manufacturer(newValue) {
      this.lazyManufacturer = window._.clone(newValue);
    }
  }
}
</script>