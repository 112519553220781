<template>
  <b-form-group
      :label="label"
      :description="description"
      :label-for="_uid.toString()"
      :valid-feedback="validFeedback"
      :invalid-feedback="invalidFeedback"
      :state="state"
  >
    <b-form-textarea
        :id="_uid.toString()"
        v-bind="$attrs"
        v-on="$listeners"
        :state="state"
        :class="inputBg"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
import {BFormGroup, BFormTextarea} from "bootstrap-vue";

export default {
  name: "CFormTextarea",
  props: {
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    validFeedback: {
      type: String,
      required: false
    },
    invalidFeedback: {
      type: String,
      required: false
    },
    state: {
      type: Boolean,
      required: false,
      default: null
    },
    inputBg: {
      type: String,
      required: false,
      default: 'bg-white'
    }
  },
  components: {BFormGroup, BFormTextarea},
}
</script>