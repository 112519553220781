<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6">
          <c-form-input :label="$t('Name')" :info="$t('For example: Tendenz, Agressia, etc.')"
                        v-model="vendor.name"
                        :placeholder="$t('Enter manufacturer name')"></c-form-input>
        </b-col>
        <b-col md="6">
          <c-input-url-prefix
              :label="$t('URL address')"
              :prefix="/vendor/"
              v-model="vendor.slug"
              :info="$t('Manufacturer URL address')"
              :placeholder="$t('Enter manufacturer URL address')"
          ></c-input-url-prefix>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-form-group :label="$t('Description')">
        <c-rich-text-area v-model="vendor.description"></c-rich-text-area>
      </b-form-group>
    </b-card>
    <b-card>
      <b-form-group :label="$t('Logo')">
        <c-form-file v-model="vendor.logo" :label="$t('Vendor logo')" preview></c-form-file>
      </b-form-group>
    </b-card>
    <b-card>
      <c-seo-preview :description="vendor.seo_description" :title="vendor.seo_title" :link="vendorLink"></c-seo-preview>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="6">
          <c-form-input :label="$t('SEO page title')" v-model="vendor.seo_title"
                        :placeholder="$t('Enter unique SEO page title')"></c-form-input>
        </b-col>
        <b-col md="6">
          <c-form-textarea :label="$t('SEO meta description')" v-model="vendor.seo_description"
                           :placeholder="$t('Enter unique SEO meta description')"></c-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {BRow, BCol, BCard, BFormGroup} from "bootstrap-vue";
import CFormInput from "@/views/cloudcart/_elements/CFormInput";
import CInputUrlPrefix from "@/views/cloudcart/_elements/CInputUrlPrefix";
import CRichTextArea from "@/views/cloudcart/_elements/CRichTextArea";
import CFormFile from "@/views/cloudcart/_elements/CFormFile";
import CSeoPreview from "@/views/cloudcart/_elements/CSeoPreview";
import CFormTextarea from "@/views/cloudcart/_elements/CFormTextarea";

export default {
  name: "ManufacturersForm",
  components: {
    CFormTextarea,
    CSeoPreview,
    CFormFile,
    CRichTextArea,
    CInputUrlPrefix,
    BRow, BCol, BCard, BFormGroup,
    CFormInput
  },
  props: {
    manufacturer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      vendor: {}
    }
  },
  computed: {
    vendorLink() {
      return 'http://localhost/vendor/' + window._.get(this.vendor, 'slug', '')
    }
  },
  watch: {
    manufacturer(newValue) {
      this.vendor = window._.clone(newValue)
    },
    vendor(newValue) {
      this.$emit('input', newValue)
    },
    'vendor.name': function (newValue, oldValue) {
      if (!oldValue || window._.isEmpty(this.vendor.slug) || this.$slugify(oldValue) === this.vendor.slug) {
        this.vendor.slug = this.$slugify(newValue)
      }
    }
  }
}
</script>