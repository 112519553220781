<template>
  <div>
    <c-navbar v-if="!hideNavbar">
      <template v-slot:title>
        {{ $t('Add category') }}
      </template>
      <template v-slot:buttons>
        <b-button variant="secondary" class="mr-2">
          {{ $t('Cancel') }}
        </b-button>
        <b-button variant="primary">
          {{ $t('Save') }}
        </b-button>
      </template>
    </c-navbar>

    <b-form>
      <b-card>
        <b-row>
          <b-col md="6">
            <c-form-input :label="$t('Name')" :placeholder="$t('Enter category name')" v-model="category.name">
              <template #label>
                {{ $t('Name') }}
                <feather-icon icon="InfoIcon"
                              v-b-tooltip.hover.top="$t('For example: shoes; computers, cupcakes')"></feather-icon>
              </template>
            </c-form-input>
          </b-col>
          <b-col md="6">
            <c-form-input :label="$t('URL address')" :placeholder="$t('Enter category name')">
              <template #label>
                {{ $t('URL address') }}
                <feather-icon icon="InfoIcon"
                              v-b-tooltip.hover.top="$t('This is the URL address to access the record which you are editing at the moment. This address will be accessible to all visitors of the site.')"></feather-icon>
              </template>
              <b-input-group>
                <b-input-group-prepend is-text class="category-slug-prepend">
                  /category/
                </b-input-group-prepend>
                <b-form-input v-model="category.slug"
                              :placeholder="$t('Enter URL address (optional)')"></b-form-input>
              </b-input-group>
            </c-form-input>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-form-group :label="$t('Description')">
          <c-rich-text-area v-model="category.description"></c-rich-text-area>
        </b-form-group>
      </b-card>
      <b-row>
        <b-col md="6">
          <b-card>
            <b-card-title>{{ $t('Parent category') }}</b-card-title>
            <b-form-group
                :description="$t('To make the category child, please select main category to which it will belongs.')">
              <c-vue-select v-model="category.parent" :placeholder="$t('Choose main category')"></c-vue-select>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <b-card-title>{{ $t('Picture') }}</b-card-title>
            <b-form-group>
              <c-form-file v-model="category.picture" preview
                           :description="$t('Category picture is used to show in your store')"></c-form-file>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-form-checkbox switch v-model="category.show_subcategories">
              {{ $t('Showing subcategories') }}
              <feather-icon icon="InfoIcon"
                            v-b-tooltip.hover.top="$t('When you enable that option on the page of this category there will be also subcategories shown.')"></feather-icon>
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-alert variant="warning" show class="p-1 small">
              <p>{{ $t('category.form.definition.line1') }}</p>
              <p>{{ $t('For example') }}: <i class="text-italic">{{ $t('category.form.definition.line2') }}</i></p>
              <p>{{ $t('category.form.definition.line3') }}</p>
            </b-alert>
            <b-form-group :label="$t('Definition')">
              <c-vue-select :placeholder="$t('Choose')"></c-vue-select>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-title>
              {{ $t('SEO configuration') }}
            </b-card-title>
            <b-row>
              <b-col>
                <c-seo-preview :description="category.seo_description" :link="categoryUrl"
                               :title="category.seo_title"></c-seo-preview>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <c-form-input :label="$t('SEO page title')" v-model="category.seo_title"
                              :placeholder="$t('Enter unique SEO page title')"></c-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <c-form-textarea :label="$t('SEO meta description')" v-model="category.seo_description"
                                 :placeholder="$t('Enter unique SEO meta description')"></c-form-textarea>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BCardTitle,
  BFormCheckbox,
  BAlert
} from "bootstrap-vue";
import CNavbar from "@/views/cloudcart/_elements/CNavbar";
import CFormInput from "@/views/cloudcart/_elements/CFormInput";
import CRichTextArea from "@/views/cloudcart/_elements/CRichTextArea";
import CVueSelect from "@/views/cloudcart/_elements/CVueSelect";
import CFormFile from "@/views/cloudcart/_elements/CFormFile";
import CFormTextarea from "@/views/cloudcart/_elements/CFormTextarea";
import CSeoPreview from "@/views/cloudcart/_elements/CSeoPreview";

export default {
  name: "CategoryForm",
  props: {
    hideNavbar: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CRichTextArea,
    CNavbar,
    BButton, BForm, BRow, BCol, BCard, BFormInput,
    BInputGroup, BInputGroupPrepend, BFormGroup,
    BCardTitle, BFormCheckbox, BAlert,
    CVueSelect,
    CFormInput,
    CFormFile,
    CFormTextarea,
    CSeoPreview
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      category: {}
    }
  },
  computed: {
    categoryUrl() {
      return 'http://localhost/category/' + window._.get(this.category, 'slug', '')
    }
  },
  watch: {
    'category.name': function (newValue, oldValue) {
      if (!oldValue || window._.isEmpty(this.category.slug) || this.$slugify(oldValue) === this.category.slug) {
        this.category.slug = this.$slugify(newValue)
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/variables/_variables';

.category-slug-prepend {
  > .input-group-text {
    background-color: lighten($primary, 35%);
    color: $primary;
    border-color: $primary;
  }

  & + input {
    border-left-color: $primary
  }
}
</style>