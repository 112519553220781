<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-dropdown :text="$t('Manage images')" variant="primary" size="sm" :disabled="!selectedItems.length">
        <b-dropdown-item @click="removeSelectedItems">{{ $t('Delete') }}</b-dropdown-item>
        <b-dropdown-item v-if="selectedItems.length === 1">{{ $t('Set as main image') }}</b-dropdown-item>
      </b-dropdown>
      <b-dropdown :text="$t('Add image')" variant="primary" size="sm">
        <b-dropdown-item @click="openImageUpload()">{{ $t('Image') }}</b-dropdown-item>
        <b-dropdown-item @click="editingImage = {files: [], type: '360'}">{{ $t('360° image') }}</b-dropdown-item>
        <b-dropdown-item @click="editingImage = {file: null, type: 'video', link: null}">{{
            $t('Youtube video')
          }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <draggable v-model="items"
               tag="ul"
               class="list-group list-group-flush d-flex flex-md-row flex-wrap mt-2"
    >
      <b-list-group-item
          v-for="(item, index) in items"
          :key="index"
          tag="li"
          class="col-md-2 border align-items-center d-flex gx-1 list-item"
      >
        <c-img :image="item.file || item.files[0]" fluid controls
               :show-controls="selectedItems.indexOf(item.id) > -1">
          <template #bottom:left>
            <b-form-checkbox v-model="selectedItems" :value="item.id"></b-form-checkbox>
          </template>
          <template #top:right>
            <b-button variant="flat" class="btn-icon p-0" @click="editingImage = item">
              <fa icon="cog" size="2x" class="text-muted"></fa>
            </b-button>
          </template>
          <template #bottom:right>
            <fa icon="images" size="2x" class="text-muted" v-if="item.type==='360'"></fa>
            <fa icon="image" size="2x" class="text-muted" v-if="item.type==='image'"></fa>
            <fa :icon="['fab', 'youtube']" size="2x" class="text-muted" v-if="item.type==='video'"></fa>
          </template>
        </c-img>
      </b-list-group-item>
    </draggable>
    <c-form-file v-model="uploadedImages" ref="imageUpload" multiple accept=".jpg, .png, .webp" hidden></c-form-file>
    <image-edit-panel :image="editingImage" v-if="editingImage !== null && editingImage.type === 'image'" width="70%"
                      :visible="editImagePanel && editingImage.type==='image'"
                      @input="updateImage"
                      @hidden="editingImage = null"
    ></image-edit-panel>
    <image360-panel :image="editingImage" v-if="editingImage !== null && editingImage.type==='360'" width="70%"
                    :visible="editImagePanel && editingImage.type === '360'"
                    @input="updateImage"
                    @hidden="editingImage = null"
    ></image360-panel>
    <youtube-video-panel :video="editingImage" v-if="editingImage !== null && editingImage.type === 'video'" width="70%"
                         :visible="editImagePanel && editingImage.type === 'video'"
                         @input="updateImage"
                         @hidden="editingImage = null"
    ></youtube-video-panel>
  </div>
</template>

<script>
import {BDropdown, BDropdownItem, BListGroupItem, BFormCheckbox, BButton} from "bootstrap-vue";
import draggable from 'vuedraggable'
import CFormFile from "@/views/cloudcart/_elements/CFormFile";
import CImg from "@/views/cloudcart/_elements/CImg";
import {v4 as uuidv4} from 'uuid'
import ImageEditPanel from "@/views/cloudcart/images/ImageEditPanel";
import Image360Panel from "@/views/cloudcart/images/Image360Panel";
import YoutubeVideoPanel from "@/views/cloudcart/images/YoutubeVideoPanel";

export default {
  name: "CMediaCollection",
  components: {
    YoutubeVideoPanel,
    Image360Panel,
    ImageEditPanel,
    CImg,
    CFormFile,
    draggable,
    BDropdown, BDropdownItem, BListGroupItem,
    BFormCheckbox, BButton
  },
  data() {
    return {
      items: [],
      selectedItems: [],
      uploadedImages: [],
      editingImage: null,
      editImagePanel: false
    }
  },
  methods: {
    openImageUpload() {
      this.$refs.imageUpload.$refs.fileInput.$refs.input.click()
    },
    updateImage(image) {
      let itemIndex = window._.findIndex(this.items, {id: image.id})
      if (itemIndex > -1) {
        this.items[itemIndex] = image
      } else {
        image.id = uuidv4();
        this.items.push(image)
      }
    },
    removeSelectedItems() {
      this.selectedItems.forEach(id => {
        let index = window._.findIndex(this.items, {id: id});
        if (index > -1) {
          this.items.splice(index, 1)
        }
        let selectedIndex = window._.findIndex(this.selectedItems, id);
        if (selectedIndex > -1) {
          this.selectedItems.splice(selectedIndex, 1)
        }
      })
    }
  },
  watch: {
    uploadedImages(images) {
      images.forEach(image => {
        this.items.push({
          id: uuidv4(),
          type: 'image',
          file: image,
          is_main: false,
          alt_text: null
        })
      })
    },
    editingImage(newValue) {
      this.editImagePanel = newValue !== null
    }
  }
}
</script>
<style scoped lang="scss">
ul {
  display: grid;
  grid-gap: 10px;
}
</style>