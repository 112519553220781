<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveImage">
          {{ $t('Save') }}
        </b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <image360-form v-model="lazyImageObject"></image360-form>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BButton} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import Image360Form from "@/views/cloudcart/images/Image360Form";

export default {
  name: "Image360Panel",
  components: {
    BSidebar, BButton,
    CSidebarHeader, Image360Form
  },
  props: {
    image: {
      type: Object,
      validator: prop => prop.type === '360' && prop.files instanceof Array
    }
  },
  data() {
    return {
      lazyImageObject: {
        files: []
      }
    }
  },
  methods: {
    saveImage() {
      this.$emit('input', this.lazyImageObject)
      this.$emit('hidden')
    }
  },
  created() {
    this.lazyImageObject = window._.clone(this.image);
  },
}
</script>