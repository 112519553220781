<template>
  <b-sidebar
      right
      backdrop
      shadow
      v-bind="$attrs"
      v-on="$listeners"
      bg-variant="light"
  >
    <template #header="{hide}">
      <c-sidebar-header @close="hide" :title="$t('Add category')">
        <b-button variant="secondary" class="mr-2" @click="hide">{{ $t('Cancel') }}</b-button>
        <b-button variant="primary" @click="saveCategory" :disabled="saveLoading">
          <b-spinner small v-if="saveLoading"></b-spinner>
          {{ $t('Save') }}
        </b-button>
      </c-sidebar-header>
    </template>
    <div class="p-2">
      <b-overlay :show="showOverlay" rounded="sm">
        <category-form hide-navbar></category-form>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import {BSidebar, BButton, BSpinner, BOverlay} from "bootstrap-vue";
import CSidebarHeader from "@/views/cloudcart/_elements/CSidebarHeader";
import CategoryForm from "@/views/cloudcart/categories/CategoryForm";

export default {
  name: "CategoriesPanel",
  components: {
    BSidebar, BButton, BSpinner, BOverlay,
    CSidebarHeader,
    CategoryForm
  },
  data() {
    return {
      saveLoading: false,
      showOverlay: false
    }
  },
  methods: {
    saveCategory() {

    }
  }
}
</script>